import Layout from './Layout';
import Block from './Block';
import { ContentfulSectionFragmentFragment } from '../data/generated/graphql';
import { Session } from '@auth0/nextjs-auth0';
import React, { useEffect } from 'react';
import { usePageTrackingContext } from '../lib/context/PageTrackingContext';
import {
  GTMVirtualPageViewContentSource,
  GTMVirtualPageViewPageType,
  GTMVirtualPageViewSubType1
} from '../helpers/useGaTracking';

export interface ILiveDealsLandingProps {
  user?: Session;
  id: string;
  title: string;
  section?: ContentfulSectionFragmentFragment;
  blocksCollection: {
    items: any[];
  };
}

const LiveDealsLanding = ({ section, blocks, title, id }) => {
  const { updatePageContext } = usePageTrackingContext();

  useEffect(() => {
    updatePageContext({
      title: title,
      subType1: GTMVirtualPageViewSubType1.Detail,
      pageType: GTMVirtualPageViewPageType.Page,
      contentId: id,
      contentSourceSystemId: GTMVirtualPageViewContentSource.Contentful
    });
  }, []);

  return (
    <Layout title="Live Deals" section={section}>
      {blocks.map((block, key) => (
        <Block id={block.sys.id} typename={block.__typename} key={key} />
      ))}
    </Layout>
  );
};

export default LiveDealsLanding;
