import { useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import querystring from 'querystring';
import { logError } from '../appInsightsClient';

export default function useAuthCheck({ requireLogin, requireAcknowledged }) {
  const router = useRouter();
  const { user, error, isLoading } = useUser();

  if (isLoading) {
    return { user, error, isLoading };
  }

  if (error) {
    logError(error);
  }

  if (requireLogin && !isLoading && user == null) {
    if (router.query?.returnTo != null) {
      router.push(
        '/api/auth/login?' +
          querystring.stringify({
            returnTo: router.asPath || '/livedeals/about?mayskip=true'
          })
      );
    } else if (router.query?.pid != null) {
      router.push(
        '/api/auth/login?' +
          querystring.stringify({
            returnTo: router.asPath || '/livedeals/about?mayskip=true'
          })
      );
    } else {
      router.push('/api/auth/login');
    }

    return { user, error, isLoading };
  }

  if (
    user &&
    requireAcknowledged &&
    (user['http://nzte.govt.nz/selected_user_metadata'] as any)
      .terms_acknowledged === false
  ) {
    router.push(
      '/terms/accept-code-of-conduct?' +
        querystring.stringify({
          returnTo: router.asPath || '/livedeals/about'
        })
    );
    return { user, userAcknowledged: false, error, isLoading };
  }

  return { user, userAcknowledged: true, error, isLoading };
}
