import { Box, Flex, Text } from '@chakra-ui/layout';
import { useClickable } from '@chakra-ui/clickable';
import { Img } from '@chakra-ui/image';
import Button from '../chakra-ui-components/Button/Button';

/**
 * Component
 *
 */
interface Props {
  onButtonClick?: (e: Event) => void;
  title: string | React.ReactNode;
  subTitle?: string;
  description?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  buttonContent?: JSX.Element | string;
  topContent?: string | JSX.Element | React.ReactNode;
  bottomContent?: string | JSX.Element | React.ReactNode;
}

const Clickable = (props) => {
  const clickable = useClickable(props);
  return (
    <Button
      borderRadius={0}
      variant="full-box-dark"
      padding={6}
      sx={{
        ':before': {
          borderRadius: 0
        }
      }}
      {...clickable}
    />
  );
};

const Auth0Modal = ({
  title,
  subTitle,
  disabled,
  description,
  children,
  onButtonClick,
  buttonContent,
  topContent,
  bottomContent
}: Props) => {
  return (
    <>
      {topContent && (
        <Box m="2rem" maxW="660px" textAlign="center">
          {topContent}
        </Box>
      )}
      <Flex
        // size="sm"
        // external={external}
        display="flex"
        flexDirection="column"
        textAlign="center"
        justifyContent="center"
        maxW={{ base: '320px', lg: '416px' }}
        m="0 auto"
        boxShadow="0 0 10px 4px #d2d1d1 !important"
      >
        <Box p="1.5rem">
          <Flex flexDirection="column" justifyContent="center" p="0.75rem">
            <Img
              height="76px"
              p="0 0 2rem 0"
              src="/logos/invest_nz_fern_logo_black.svg"
            />
            <Text fontSize="20px" as="h4" fontWeight="bold">
              {title}
            </Text>
          </Flex>
          <Box p="0.875rem" borderTop="1px solid #ececec" pt={8} mt={2}>
            <Box>{subTitle}</Box>
            <Box>{description}</Box>
            {children}
          </Box>
        </Box>
        {onButtonClick && buttonContent && (
          <Clickable
            onClick={(e: Event) => {
              onButtonClick && onButtonClick(e);
            }}
            data-testid="auth0-accept-btn"
            alt="button"
            disabled={disabled}
          >
            {buttonContent}
          </Clickable>
        )}
      </Flex>
      {bottomContent && (
        <Box mt={6} mb={{ base: '2rem', lg: '5rem' }}>
          <Text as="span">{bottomContent}</Text>
        </Box>
      )}
    </>
  );
};

export default Auth0Modal;
