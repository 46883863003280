import { Center } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import ErrorPage from '../_error';

import Auth0Modal from '../../components/Modals/Auth0Modal';
import LiveDealsLandingPage from '../../components/LiveDealsLandingPage';
import useAuthCheck from '../../lib/hooks/useAuthCheck';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { getApolloClientInstance } from '../../server/ssrapollo';
import {
  queryLayout,
  queryBlocks,
  queryLivedealsAboutPage,
  queryAnchorLinkItems,
  queryBlockAccordions
} from '../../server/ssrqueries';
import { ssrQueriesDispatch } from '../../server/ssrQueries/dispatch';
import {
  ServerSideRenderingDataContext,
  ServerSideRenderingDataInterface,
  useServerSideRenderingDataContext
} from '../../components/ServerSideRenderingDataContext';
import { getNavigationForPage } from '../../server/navigation';
import { getAuth0 } from '../../lib/auth0';
import React from 'react';

export const LIVE_DEALS_LANDING_PAGE_KEY_LOGGED_OUT =
  'livedeals-logged-out-user';
export const LIVE_DEALS_LANDING_PAGE_KEY_LOGGED_IN = 'livedeals-logged-in-user';

export const About = () => {
  const router = useRouter();
  const { query } = router;
  const { user, userAcknowledged, isLoading } = useAuthCheck({
    requireLogin: false,
    requireAcknowledged: true
  });

  if (!user && query && query.message === 'This URL can be used only once') {
    return <UsedResetLinkModal />;
  }
  if (typeof window !== 'undefined') {
    if (
      !user &&
      query &&
      query.success === 'true' &&
      query.message ===
        'You can now login to the application with the new password.'
    ) {
      router.push('/api/auth/login#reset');
      return null;
    }

    if (query.success === 'false' && query.message === 'Access expired.') {
      router.push('/livedeals/password-reset-link-expired');
    }

    if (
      user &&
      (user['http://nzte.govt.nz/selected_user_metadata'] as any).logins_count >
        1 &&
      query &&
      query.mayskip === 'true'
    ) {
      router.push('/livedeals');
    }
  }

  const dataContext = useServerSideRenderingDataContext();
  const { data, errors, loading } = dataContext.PAGE_QUERY;

  if ((user && !userAcknowledged) || isLoading || loading) return null;
  if (errors) return <ErrorPage statusCode={500} error={errors} />;

  const page = data.pageCollection.items[0];
  if (!page) return <ErrorPage statusCode={404} />;

  const { section, blocksCollection, title, sys } = page;
  const blocks = blocksCollection.items;

  return (
    <LiveDealsLandingPage
      section={section}
      blocks={blocks}
      title={title}
      id={sys.id}
    />
  );
};

function UsedResetLinkModal() {
  const router = useRouter();
  const goToLogin = (e: Event) => {
    e.preventDefault();
    router.push('/api/auth/login');
  };
  return (
    <Center
      w="100%"
      h={{ base: '100%', lg: '100%' }}
      flexDirection="column"
      textAlign="center"
      alignContent="center"
    >
      <Auth0Modal
        title="Oops! You have already set your new password"
        subTitle="Return to the login screen to try logging in again, or to reset your password."
        buttonContent={<span>Go to Login</span>}
        onButtonClick={goToLogin}
      />
    </Center>
  );
}

const ContextEachPage = (props: {
  queryResults: ServerSideRenderingDataInterface;
}) => {
  const { queryResults, ...passThroughProps } = props;
  return (
    <ServerSideRenderingDataContext.Provider value={props.queryResults}>
      {/* @ts-ignore */}
      <About {...passThroughProps} />
    </ServerSideRenderingDataContext.Provider>
  );
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
): Promise<{ props: { queryResults: ServerSideRenderingDataInterface } }> => {
  const { req, res } = context;
  const apolloClient = getApolloClientInstance();

  const session = await getAuth0().getSession(req, res);

  const pageKey =
    session && session.user
      ? LIVE_DEALS_LANDING_PAGE_KEY_LOGGED_IN
      : LIVE_DEALS_LANDING_PAGE_KEY_LOGGED_OUT;

  const ssrQueryMatrix = {
    layoutProps: { action: queryLayout(apolloClient), param: null },
    pageProps: {
      action: queryLivedealsAboutPage(apolloClient, pageKey),
      param: null
    },
    pageBlocksProps: {
      action: queryBlocks(apolloClient),
      param: (pageProps) =>
        pageProps?.data?.pageCollection?.items?.[0]?.blocksCollection?.items ??
        []
    },
    pageBlockAccordions: {
      action: queryBlockAccordions(apolloClient),
      param: (pageBlocksProps) =>
        pageBlocksProps
          ?.filter(
            (block) => block.data?.block.__typename === 'BlockAccordions'
          )
          .map((block) => block.data.block.accordionsCollection.items)
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    },
    pageAnchorLinksContent: {
      action: queryBlocks(apolloClient),
      param: (pageBlocksProps) =>
        pageBlocksProps
          ?.filter(
            (block) => block.data?.block.__typename === 'BlockAnchorLinks'
          )
          .map((block) => block.data.block?.contentCollection?.items)
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    },
    pageAnchorLinksItems: {
      action: queryAnchorLinkItems(apolloClient),
      param: (pageBlocksProps) =>
        pageBlocksProps
          ?.filter(
            (block) => block.data?.block.__typename === 'BlockAnchorLinks'
          )
          .map((block) => block.data.block?.itemsCollection?.items)
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    },
    pageAnchorLinkAccordions: {
      action: queryBlockAccordions(apolloClient),
      param: (pageAnchorLinksItems) =>
        pageAnchorLinksItems
          ?.filter(
            (anchorLink) =>
              anchorLink.data?.block.__typename === 'BlockAccordions'
          )
          .map(
            (anchorLink) => anchorLink.data?.block.accordionsCollection.items
          )
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    }
  };
  const queryProps = await ssrQueriesDispatch(ssrQueryMatrix);

  //Based on the page data received get the corresponding navigation module for that page.
  const pageNavigationModuleId =
    queryProps['pageProps'].data?.pageCollection.items[0]?.navigationModule?.sys
      .id || '';

  const navigationModuleData = await getNavigationForPage(
    apolloClient,
    queryProps['pageProps'].data.pageCollection.items[0]?.sys.id,
    pageNavigationModuleId
  );
  const queryResults: ServerSideRenderingDataInterface = {
    BLOCK_ACCORDIONS: [
      ...queryProps['pageBlockAccordions'],
      ...queryProps['pageAnchorLinkAccordions']
    ],
    PAGE_404: null,
    PAGE_BLOCKS: [
      ...queryProps['pageBlocksProps'],
      ...queryProps['pageAnchorLinksContent'],
      ...queryProps['pageAnchorLinksItems']
    ],
    LIVE_DEAL_FILTERS_QUERY: null,
    PAGE_LAYOUT: queryProps['layoutProps'],
    PAGE_NAVIGATION_MODULE_DATA: navigationModuleData,
    PAGE_QUERY: queryProps['pageProps']
  };

  return {
    props: {
      queryResults
    }
  };
};

export default ContextEachPage;
